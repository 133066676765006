<template>
	<div class="couponBox" :class="!isMobile ? 'pcbox' : ''">
		<div class="conBox1">
			<img :src="bg1">
			<div class="msgBox">
				<div class="msgtext">
					<p>축하드립니다!</p>
					<p>본 플랫폼에서 증정한 10만원 할인 쿠폰을 획득했습니다!</p>
					<p>웹 페이지에서 휴대폰 홈 화면에 추가하시면 바로 할인 쿠폰을 활성화해서 사용하실 수 있습니다.</p>
					<p>10만원 할인 쿠폰은 본 플랫폼 모든 게임에서 할인된 가격으로 구매하실 수 있습니다. (*계정 당 할인쿠폰 1회만 수령 가능)</p>
				</div>
				<div class="welfareBox">
					<div class="welfareItem">
						<p>1000원 할인 쿠폰</p>
						<p class="big">100%</p>
					</div>
					<div class="plusItem">
						<van-icon name="plus" size="22" color="#000" />
					</div>
					<div class="welfareItem">
						<p> 99000원 할인 쿠폰</p>
						<p class="big">50%</p>
					</div>
				</div>
				<div class="desktopBox" @click.stop="addToHome">
					<img :src="couponBtn">
				</div>
			</div>
		</div>
		<div class="conBox2">
			<img :src="bg2">
			<div class="rule1Box">
				<div class="msgtext">
					<p><span>1.</span>웹에서 휴대폰 바탕에 추가하면 10만원 할인 쿠폰을 활성화하여 사용하실 수 있습니다</p>
					<p><span>2.</span>플레이어는 공식 게임팩을 통해 할인권을 사용하여 할인된 가격으로 충전할 수 있습니다.</p>
					<p><span>3.</span>10만원 할인 쿠폰에 포함된 것: 1000원 전액 할인 쿠폰은 100% 할인 가능하며, 99000원 반값 할인 쿠폰은 50% 할인됩니다.</p>
				</div>
			</div>
			<div class="rule2Box">
				<div class="msgtext">
					<p><span>1.</span>휴대폰 기본 브라우저 사용하시는 것을 건의합니다 (브라우저에 따라 다소 차이가 있습니다) </p>
					<p><span>2.</span>추가할 웹 사이트 ( 웹 페이지 )를 열기.</p>
					<p><span>3.</span>웹 페이지 하단에서 설정 (도구 또는 + 번호라고도 함) 찾기.</p>
					<p><span>4.</span>사용 방법을 잘 모르신다면 저희 고객센터 직원에게 연락하면 안내해 드리겠습니다.</p>
					<p class="kakao">KAKAO Id: GM20238</p>
				</div>
			</div>
			<div class="rule3Box">
				<img class="imgtip" :src="couponUsedemo" />
				<div class="msgtext">
					<p>결제 페이지에서 할인권 선택 (50%-90%할인) 후 결제를 취소한 경우, 할인권은 30분 후 다시 반환됩니다.</p>
				</div>
			</div>
		</div>
		<popupAddScreen ref="popupScreen" :statdes="`抵扣券详情页按钮`" />
		
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import useConfigStore from '@/store/modules/config'
	import popupAddScreen from '@/view/coupon/popup.vue'
	
	const { proxy } = getCurrentInstance();
	const lang = proxy.$i18n.locale
	const bg1 = require('@/assets/images/'+lang+'/coupon_bg1.jpg')
	const bg2 = require('@/assets/images/'+lang+'/coupon_bg2.jpg')
	const couponBtn = require('@/assets/images/'+lang+'/coupon_popup_btn2.png')
	const couponUsedemo = require('@/assets/images/'+lang+'/coupon_usedemo.png')
	const pcsteps = require('@/assets/images/'+lang+'/pc_steps.png')
	
	const steps = {
		'samsung': [
			{ img: require('@/assets/images/'+lang+'/samsung_step1.png'), txt: '우측 하단 동그라미 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/samsung_step2.png'), txt: '현재 페이지 추가를 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/samsung_step3.png'), txt: '홈 화면을 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/samsung_step4.png'), txt: '추가를 클릭하기'},
		],
		'naver': [
			{ img: require('@/assets/images/'+lang+'/naver_step1.png'), txt: '우측 하단 동그라미 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/naver_step2.png'), txt: '홈 화면에 추가를 클릭하기'},
			{ img: require('@/assets/images/'+lang+'/naver_step3.png'), txt: '추가를 클릭하기'},
		]
	}
	const popupScreen = ref(null)
	const deferredPrompt = ref(null);
	const showGuides = ref(false)
	const activePlat = ref('')
	const activeStep = ref(0)
	const activeStepItem = ref([])
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	
	onMounted(() => {
		
	})
	const addToHome = () => {
		popupScreen.value.addToHome()
		
	}
	
</script>

<style lang="less" scoped>
	.pcbox{
		.conBox1,.conBox2{
			max-width: 600px;margin: 0 auto;
		}
	}
	.couponBox{
		height: calc(100% - 90px);overflow-y: scroll;color: #14181B;
		.conBox1,.conBox2{
			position: relative;font-size: 0;clear: both;
			img{
				width: 100%;
			}
			.msgBox{
				position: absolute;left: 50%;top: 58%;transform: translate(-50%,0);z-index: 111;
				width: 80%;height: 30%;
				p{
					text-align: center;font-size: 0.75rem;
				}
				.msgtext{
					height: 60%;padding: 0.75rem;box-sizing: border-box;
					p{
						font-weight: 600;line-height: 1.0625rem;
					}
				}
				.welfareBox{
					display: flex;align-items: center;justify-content: space-around;height: 40%;
					p.desc{
						line-height: 2.5rem;
					}
					.big{
						font-size: 1.875rem;font-weight: 800;font-style: italic;
					}
				}
				.desktopBox{
					margin: 1.75rem auto 0;width: 80%;
				}
			}
			.rule1Box,.rule2Box,.rule3Box{
				position: absolute;left: 50%;top: 8%;transform: translate(-50%,0);z-index: 111;
				width: 88%;height: 21%;font-size: 0.75rem;padding: 0.625rem 0.625rem;box-sizing: border-box;
				.msgtext{
					height: 100%;
					p{
						text-align: left;font-weight: 600;line-height: 1.0625rem;margin-bottom: 0.1875rem;
						span{
							display: inline-block;width: 1.0625rem;height: 1.0625rem;line-height: 1.0625rem;
							border-radius: 50%;background: #55E6F0;text-align: center;margin-right: 0.125rem;
						}
						&.kakao{
							display: inline-block;height: 0.9375rem;
							font-size: 0.9375rem;font-weight: 800;border-bottom: 0.1875rem solid #FE8F00;
						}
					}
				}
			}
			.rule3Box{
				top: 74%;
				display: flex;align-items: center;justify-content: center;
				.imgtip{
					max-width: 36%;margin: 5%;
				}
				.msgtext{
					padding: 0 3%;
					display: flex;align-items: center;
				}
			}
			.rule2Box{
				top: 41%;
			}
		}
	}
</style>